<template>
  <div :style="rootStyle">
    <v-tabs
      v-model="value"
      :color="control.style['color']"
      :background-color="control.style['background-color']"
      :dark="control.config.theme == 'dark'"
      :vertical="control.config.direction == 'vertical'"
      :slider-color="
        control.config.theme == 'dark'
          ? 'rgba(255,255,255,0.2)'
          : 'rgba(0,0,0,0.2)'
      "
      :slider-size="
        control.config.direction == 'vertical'
          ? control.size.width
          : control.size.height
      "
      height="100%"
    >
      <v-tab
        v-for="item in items"
        :key="item.uuid"
        @click="onClick(item.pageId)"
        v-text="item.name"
        :style="itemStyle"
      ></v-tab>
    </v-tabs>
  </div>
</template>

<script>
import client from "../utils/client";
export default {
  props: { control: Object, isView: Boolean, pageId: String },
  data() {
    return {
      value: undefined,
    };
  },
  computed: {
    rootStyle() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      delete result["color"];
      delete result["background-color"];
      delete result["font-size"];
      delete result["font-weight"];
      delete result["padding"];
      return result;
    },
    itemStyle() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      if (this.control.config.direction == "vertical") {
        result["width"] = this.control.size.width + "px";
        result["height"] = "auto";
      } else {
        result["height"] = this.control.size.height + "px";
      }
      result["maxWidth"] = "none";
      delete result["opacity"];
      delete result["color"];
      delete result["background-color"];
      delete result["box-shadow"];
      return result;
    },
    items() {
      if (this.control.config.tabs && this.control.config.tabs.length > 0)
        return this.control.config.tabs;
      else
        return [
          { name: "导航1", pageId: "1" },
          { name: "导航2", pageId: "2" },
          { name: "导航3", pageId: "3" },
        ];
    },
  },
  mounted() {
    let arr = this.control.config.tabs;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].pageId == this.pageId) {
        this.value = i;
        return;
      }
    }
    this.value = 0;
    if (this.control.config.pageContainer) {
      this.onClick(this.items[0].pageId);
    }
  },
  methods: {
    onClick(id) {
      if (this.isView) {
        if (this.control.config.pageContainer) {
          client.$emit("prop-set", {
            uuid: this.control.config.pageContainer,
            prop: "config.pageId",
            value: id,
          });
        } else {
          client.$emit("navigate", { page: { uuid: id } });
        }
      }
    },
  },
};
</script>
